import restaurant from "./images/taboo.jpg";
import biserica from "./images/biserica1.jpg";
import img_card from "./images/card.jpeg";
import imgOmSuflet from "./images/headerres.webp";
import imgheader from "./images/hm.jpeg";
import imgheaderm from "./images/hm.jpeg";
import img2 from "./images/hm.jpeg";
import imgheadermiini from "./images/3.jpeg";
import imgheadermiinimb from "./images/3.jpeg";

{/*gabriela22alina22@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatie Online - Gabriela & Florian",
        mire: "Gabriela",
        mireasa: "Florian",
        data: "20 August 2022",
        data_confirmare: "10 august 2022",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "gabriela22alina22@gmail.com", 
       tel: "+40 (735) 691 319",
       phone: "tel:+40735691319",
       viber: "viber://chat?number=%2B40735691319",
       whatsapp: "https://wa.me/+40735691319",
       messenger: "https://www.messenger.com/t/100001161927979&sk",
       tel1: "+44 7802 635568",
       phone1: "tel:+447802635568",
       viber1: "viber://chat?number=%2B447802635568",
       whatsapp1: "https://wa.me/+447802635568",
       messenger1: "https://www.messenger.com/t/florian.rotila",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Cununia Religioasă",
            localul: "Biserica",
            name: "Sf. M. Mc. Gheorghe",
            data: "20 august 2022, sâmbătă, ora 16:00",
            adress: "Strada Sudului, Slobozia, România",
            harta: "https://goo.gl/maps/MtPLfFur3j4qVrnv5",
            iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2828.160612429133!2d24.878533915537318!3d44.85902497909842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b2bc881909f05f%3A0x2464b8446cdfde3d!2sBiserica%20Sf%C3%A2nta%20Vineri!5e0!3m2!1sro!2s!4v1651305860898!5m2!1sro!2s"
        },
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Taboo Events",
            data: "20 august 2022, sâmbătă, ora 20:00",
            adress: "str. Polivalentă 6, Slobozia 920043, România",
            harta: "https://goo.gl/maps/hdozUmfp3KpbzsP56",
            iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2830.192723258335!2d24.87527551553586!3d44.81763827909864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b2a3e86092ef25%3A0xe449184984c4ace7!2sAcropole%20Events%20Pitesti!5e0!3m2!1sro!2s!4v1651305778790!5m2!1sro!2s"
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ ȘI ÎNCREȘTENAREA MICUȚULUI NOSTRU MILAN-ANDREI!",
            message: "În ziua în care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, \<br\> vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgOmSuflet,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            message1: "CU BINECUVÂNTAREA PĂRINȚILOR",
            parintii_nume1: "Ioana Cioacă & Costel Stoica ",
            parintii_nume2: "Dumitra Radu & Gheorghe Rotila ",
            nasii: "ȘI SUSȚINEREA NAȘILOR",
            nasii_nume: "Mirela și Iulian Drăgan ",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "\"Căsătoria este un puzzle din milioane de momente memorabile, care crează povestea noastră!\" ",
            message: "",
        }
    ],

}

export default data;